@import url("https://use.typekit.net/lcp1zih.css");

html, body {
   font: normal normal 16px/1.75 var(--f-talk);

}

.strong, strong {
   font-variation-settings: 'wght' var(--f-weight-heavy), 'wdth' var(--f-width-normal);
}

.bigger {
   --_scale: 1.75vw; 
   font-size: clamp(1rem, var(--_scale), 1.75rem);
   @include bp(lap) {
       --_scale: 3vw;
   }
}
h1, h2, h3, h4, h5 {
   margin: 0;
}
h2, h3, h4 {
   font-weight: 900;
}
h2, h3, h5 {
   font-family: var(--f-scream);
   line-height: .78;
}

.screamer {
   font-size: clamp(var(--_min), var(--_scale), var(--_max));
}

h2 {
   &.screamer {
       --_min: 7rem;
       --_scale: 20vw;
       --_max: 20rem;
       @include bp(hands) {
         --_min: 6rem;
           --_scale: 24vw;
       }
   }
}
h3 {
   & + p {
       margin-top: 1rem;
   }
   &.screamer {
       --_min: 3.25rem;
       --_scale: 6.5vw;
       --_max: 8rem;
       @include bp(hands) {
           --_min: 2.125rem;
           --_scale: 8vw;
           text-wrap: wrap;
       }
   }
}

.sneaky {
  opacity: 0.25;
}

p, h4 {
   font-size: 1.75rem;
   line-height: 1.5;
   font-variation-settings: 'wght' var(--f-weight-normal), 'wdth' var(--f-width-normal); 

   @include bp(lap) {
       font-size: 1.25rem;
   }
}
a {
   color: currentColor;
   text-decoration: none;
}
p {
   a {
       font-variation-settings: 'wght' var(--f-weight-heavy), 'wdth' var(--f-width-normal); 
       text-decoration: underline;
       display: inline-block;

       &:hover {
           color: white;
       }
   }
}

h4 {
font-variation-settings: 'wght' var(--f-weight-heavy), 'wdth' var(--f-width-normal); 
&.screamer {
   font-family: var(--f-scream);
   font-size: 3rem;
   line-height: .78;

   & + p {
      margin-top: 0.5em;
   }
}
}

h5 {
   font-size: 1.25rem;
   letter-spacing: 0.05em;
}
