body {
	background: var(--c-spit-green-600);
}

// lander customizations
#spitSpots {
	--_bg-color: var(--c-spit-green-600);
	--_content-color: var(--c-gob-whitish);
	--_site-pad: 24px;
	--_grid-gap: var(--_site-pad);
	--_section-gutter: 64px;
	--_section-spacing: calc(var(--_section-gutter) * 2);
	--_pane-padding: 40px;
	color: var(--_content-color);
	padding: var(--_site-pad);
	position: relative;
	isolation: isolate;
	max-width: 100vw;
	overflow: clip;

	&::before {
		content: "";
		background: var(--_bg-color);
		position: fixed;
		inset: 0;
		z-index: -1;
	}

	@include bp(lap) {
		--_section-gutter: var(--_pane-padding);
	}

	@include bp(hands) {
		--_pane-padding: 24px;
	}
}

// global masthead
#head {
	@include flex($justify: space-between, $gap: 16px);
	// margin-bottom: var(--_section-spacing);
}

#hero {
	--_art-size: 95%;
	border-radius: var(--radius-small) var(--radius-small) var(--radius-big) var(--radius-big);
	background: #41712f;
	padding: 40px var(--_section-gutter);
	position: relative;
	isolation: isolate;
	overflow: hidden;
	z-index: 2;
	filter: drop-shadow(0 12px 40px var(--c-spit-green-800));
	@include flex(column, stretch, center, var(--_section-spacing));

	.art {
		position: absolute;
		z-index: -1;
		right: 0;
		transform: translateX(5%);
		width: var(--_art-size);
		aspect-ratio: 1.22 / 1;

		.layer {
			position: absolute;
			inset: 0;
		}

		@include bp(hands) {
			--_art-size: 100%;
			transform: none;
			scale: 1.75;
		}
	}
}

#heroLight {
	mix-blend-mode: overlay;
	animation: glow 1000ms alternate-reverse infinite var(--ease-linear);
}

#heroShock {
	animation: shock 5ms alternate-reverse infinite var(--ease-linear);
}

#heroSkele {
	animation: shock 15ms alternate-reverse infinite var(--ease-linear);
}

#heroEye {
	position: absolute;
	width: 25%;
	aspect-ratio: 1 / 1;
}

#heroScreen {
	@include flex;
	width: 19.5%;
	height: 24.5%;
	background: black;
	position: absolute;
	right: 27%;
	top: 40.25%;
	scale: 1.05;
	// z-index: 2;
	// opacity: .5;
	@include img-fit;
}

#bigNav {
	@include flex($gap: 16px);
	font-variation-settings: "wght" var(--f-weight-heavy), "wdth" var(--f-width-normal);
	border-radius: var(--radius-small);
	background: var(--c-spit-green-800);
	padding: 8px 8px 8px 16px;

	.active {
		text-decoration: underline !important;
		text-underline-offset: 4px;
		pointer-events: none;
	}

	.navItem {
		color: var(--c-gob-whitish);
		text-decoration: none;

		&:hover {
			color: var(--mix-spit);
		}
	}

	@include bp(hands) {
		padding: 0;
		background: none;

		a:not([class^="button"]) {
			display: none;
		}
	}
}

#intro {
	@include flex(column, center, flex-start, 40px);
	position: relative;
	isolation: isolate;
	text-align: center;
	margin-top: 0;
	padding-block: var(--_section-spacing);

	&::before,
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 100vw;
	}

	&::before {
		background: transparent url("../../public/i/logos/spit-tv-title.png") no-repeat center / 150%;
		z-index: -1;
		opacity: 0.05;
		mix-blend-mode: overlay;
		filter: grayscale(1);
		bottom: 0;
		transform-origin: bottom;
		height: 200%;
	}

	&::after {
		z-index: -1;
		height: 100%;
		background-image: linear-gradient(to top, var(--c-spit-green-600), transparent);
	}

	.copy {

		// max-width: 960px;
		// margin-inline: auto;
		h3 {
			max-width: 56ch;
			text-wrap: balance;
		}

		[class^="button"] {
			margin-top: 40px;
		}
	}
}

#spitLogo {
	min-width: min(360px, 100%);
	flex: 1;
	filter: drop-shadow(1px 2px var(--c-spit-green-800));

	img {
		width: 100%;
	}
}

#spitSocial {
	// margin-top: calc(var(--_section-spacing) * -0.25);
	display: flex;
	flex-wrap: wrap;
	gap: var(--_grid-gap);

	@include bp(hands) {
		gap: calc(var(--_grid-gap) / 2);
	}
}

@for $i from 1 through 3 {
	.socialCard:nth-of-type(#{$i}) {
		$rotation: random(200) / 100 - 1; // Generates a random number between -1 and 1 with up to 2 decimal places
		rotate: #{$rotation}deg;
	}
}

.socialCard {
	@include flex(column, stretch, flex-end);
	border-radius: var(--radius-big);
	background: var(--c-spit-green-400);
	flex: 1;
	text-align: center;
	aspect-ratio: 1 / 1;
	height: auto;
	overflow: hidden;

	.stupidSquare {
		width: 100%;
		display: block;
	}

	h4 {
		padding: var(--_site-pad);
		font-variation-settings: "wght" 720, "wdth" var(--f-width-normal);
		// font-size: 1.25rem;
	}

	.splat {
		pointer-events: none;
		height: 100%;
		scale: 1.15;
		@include img-fit(bottom);
		transform-origin: bottom;
		filter: brightness(1.05);
	}

	&:hover {
		rotate: none;
		filter: drop-shadow(0 12px 40px var(--c-spit-green-800));

		.splat {
			scale: 1;
			z-index: 2;
			filter: brightness(2);
		}

		h4 {
			color: var(--c-spit-green-200);
		}
	}

	@include bp(hands) {
		min-height: 120px;
	}
	@include bp(hand) {
		min-height: 96px;
	}
}



#episodes,
#cast,
#intro {
	h2 {
		margin-block: 24px 8px;

		&+.wiggleDivide {
			opacity: 1;
		}
	}

	h3 {
		font-size: 2rem;
		line-height: 1.25;
		font-family: var(--f-talk);
		font-variation-settings: "wght" 720, "wdth" var(--f-width-normal);

		@include bp(hands) {
			font-size: 1.5rem;
		}
	}
}

#seasonNav {
	@include flex($justify: flex-start, $gap: 12px);

	.tab {
		color: var(--c-spit-green-200);
		background: var(--c-spit-green-800);
		@include flex;
		line-height: 1;
		padding: 10px 20px 6px;
		border-radius: 40px;


		&:not(.active) {
			color: var(--c-spit-green-400);
			opacity: 0.5;
		}
	}
}


.episode {
	@include flex($justify: space-between, $gap: var(--_section-gutter));

	@include bp(hands) {
		@include flex(column, stretch, flex-start, 12px);
	}

	margin-top: -2px;

	[class^="button"] {
		text-wrap: nowrap;
		rotate: 0deg;

		&.unreleased {
			opacity: 0.5;
			color: var(--c-spit-green-800);
			pointer-events: none;
		}
	}

	&::before,
	&::after {
		opacity: .25;
	}

	&:not(.live) {
		[class^="button"] {
			&:not(.unreleased) {
				display: none;
			}
		}

		.episodeRelease {
			display: none;
		}
	}

	&.live {
		[class^="button"] {
			&.unreleased {
				display: none;
			}

			&:not(.unreleased) {

				&::before {
					content: '';
					width: 16px;
					min-width: 16px;
					height: 20px;
					margin-right: 0.5em;
					scale: 1.5;
					transform: translateY(-1px);
					rotate: -4deg;
					background: transparent url("data:image/svg+xml,%3Csvg width='28' height='32' viewBox='0 0 28 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 13.125H27.9375C25.0625 13.125 22.4375 12.25 20.3125 10.6875V21.875C20.3125 27.5 15.75 32 10.125 32C4.5 32 0 27.5 0 21.875C0 16.25 4.5 11.6875 10.125 11.6875C10.625 11.6875 11.0625 11.75 11.5625 11.8125V17.4375C11.0625 17.25 10.625 17.1875 10.125 17.1875C7.5625 17.1875 5.4375 19.3125 5.4375 21.875C5.4375 24.4375 7.5625 26.5625 10.125 26.5625C12.6875 26.5625 14.8125 24.4375 14.8125 21.875V0H20.3125C20.3125 0.0625 20.3125 0.0625 20.3125 0.125C20.3125 0.5625 20.3125 1 20.4375 1.4375C20.8125 3.5 22.0625 5.3125 23.8125 6.4375C25 7.25 26.4375 7.6875 27.9375 7.6875C27.9375 7.6875 27.9375 7.6875 28 7.6875V13.125Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat center / 16px;
				}
			}
		}
	}
}


.episodeMeta {
	@include flex($justify: flex-start, $wrap: wrap);

	h3 {
		margin-right: 12px;
	}
}

.episodeTitle {
	color: var(--c-spit-green-200);
}

.episodeRelease {
	color: var(--c-spit-green-800);
}

.episodeDescription {
	line-height: 1.125;
	font-size: 1.25rem;
}

#castGrid {
	margin-top: var(--_grid-gap);

	@for $i from 1 through 11 {
		.castmate:nth-of-type(#{$i}) {
			$rotation: random(200) / 100 - 1; // Generates a random number between -1 and 1 with up to 2 decimal places
			rotate: #{$rotation}deg;
		}
	}

	.castmate {
		--_pad: 8px;
		@include flex(column, stretch, flex-start, var(--_pad));
		border-radius: var(--radius-big);
		overflow: hidden;
		border: 2px solid var(--c-spit-green-400);
		padding: var(--_pad);
		position: relative;
	}

	.castmatePfp {
		border-radius: calc(var(--radius-big) - var(--_pad));
		overflow: hidden;
	}

	.castmateAbout {
		text-align: center;
		padding: 16px 16px 8px;
		z-index: 2;
		position: absolute;
		inset: auto calc(var(--_pad) * 2) calc(var(--_pad) * 2);
		background: var(--c-spit-green-800);
		border-radius: var(--radius-small);
	}

	.castmateName {
		color: var(--c-spit-green-200);
		// font-variation-settings: "wght" 720, "wdth" var(--f-width-normal);
		line-height: 1;

		&+p {
			margin-top: 4px;
		}
	}

	.castmateBio {
		font-size: 1.25rem;
		line-height: 1.25;
		text-wrap: balance;
	}
}

#action {
	@include flex(column, center, flex-end, 40px);
	background: var(--c-brand-red);
	padding: 64px 16vw;
	border-radius: var(--radius-small);
	text-align: center;
	position: relative;
	isolation: isolate;
	overflow: hidden;

	@include bp(hands) {
		padding-inline: 24px;
	}

	@include bp(hands) {
		padding-block: 40px;
	}
}

#beet {
	position: absolute;
	top: 0;
	left: 160px;
	width: min(240px, 24vw);
	transform: translateY(-35%);
	z-index: 2;
	pointer-events: none;
	filter: saturate(1.25);

	@include bp(hands) {
		display: none;
	}
}

#vandalism {
	position: relative;
	@include flex;
}

#wheatPaste {
	position: absolute;
	width: 240px;
	scale: 1.25;
	transform: translateX(-5%);
	filter: saturate(1.25);

	@include bp(hand) {
		transform: translateX(-10%);
		width: 200px;
	}
}

#s1Poster {
	@include flex;
	margin: 0 auto;
	// border: 3px solid black;
	width: min(800px, 100%);
	// border-radius: var(--radius-small);
	overflow: hidden;
	rotate: 1deg;
	filter: drop-shadow(0 12px 40px var(--c-spit-green-800)) saturate(1);

	&:hover {
		scale: 1.1;
		filter: drop-shadow(0 12px 40px var(--c-spit-green-800)) saturate(1.125);
	}
}

#soundToggle {
	cursor: none;
	img {
		width: 16px;
		pointer-events: none;
	}
}