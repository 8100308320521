@keyframes awake {
    0% {
        transform: scale(1);
    }

    14% {
        transform: scale(1.125);
    }

    28% {
        transform: scale(1);
    }

    42% {
        transform: scale(1.125);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes backAndForth {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(120%);
    }
}

@keyframes leave {
    to {
        transform: translateY(-40%);
        scale: 1.75;
        box-shadow: 0 200px 200px black;
    }
}

@keyframes reveal {
    from {
        opacity: 0;
        filter: grayscale(1);
        transform: translateY(calc(var(--_section-gutter) * -1));
    }
}

@keyframes glow {
    to {
        opacity: .75;
    }
}

@keyframes shock {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}