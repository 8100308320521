@mixin img-fit($position: center) {
	img, video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: $position;
	}
}
@mixin flex($direction: row, $align: center, $justify: center, $gap: 0, $wrap: nowrap, $type: flex) {
	display: $type;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $justify;
	gap: $gap;
	flex-wrap: $wrap;
}

@mixin bp($point) {
	$bp-hand: "(max-width: #{$q-hand})";
	$bp-hands: "(max-width: #{$q-hands})";
	$bp-lap: "(max-width: #{$q-lap})";
	$bp-desk: "(max-width: #{$q-desk})";
	$bp-vision: "(min-width: #{$q-desk})";

	@if $point == hand {
		@media #{$bp-hand} {
			@content;
		}
	}
	@if $point == hands {
		@media #{$bp-hands} {
			@content;
		}
	}
	@if $point == lap {
		@media #{$bp-lap} {
			@content;
		}
	}
	@if $point == desk {
		@media #{$bp-desk} {
			@content;
		}
	}
	@if $point == vision {
		@media #{$bp-vision} {
			@content;
		}
	}
}